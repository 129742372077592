


































































































































.pop-enter-active {
  animation: pop-in 0.25s;
}
.pop-leave-active {
  animation: pop-in 0.25s reverse;
}
@keyframes pop-in {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
